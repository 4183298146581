.prevButton {
  display: flex;
  align-items: center;
  user-select: none;
  padding: 12px 16px;
  background: var(--secondaryTextColor);
  border: 2px solid var(--secondaryTextColor);
  border-radius: 20px 0px;

  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: var(--secondaryColor);

  transition:
    color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  svg {
    margin-right: 15px;

    path {
      transition: fill 0.3s ease-in-out;

      fill: var(--secondaryColor);
    }
  }

  &:hover {
    background: var(--secondaryColor);
    color: var(--secondaryTextColor);

    svg {
      path {
        fill: var(--secondaryTextColor);
      }
    }
  }
}
